import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import UnrecognizableOpenGraphImage from '../images/social-iceland.jpg';
export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "iceland.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Unrecognizable Earth" description="How a handful of human generations have transformed the surface of the planet." image={'https://storybook.earth' + UnrecognizableOpenGraphImage} pageCanonical={'https://storybook.earth/unrecognizable-earth'} mdxType="SEO" />
    <Img className="story-header-gatsby-img" fluid={props.data.imageOne.childImageSharp.fluid} mdxType="Img" />
    <div className="story-content">
      <p className="txt-low-emph txt-center">Iceland is known for harsh landscapes of rock and snow, but prior to human settlement nearly half of the island was covered in birch forest and woodland.</p>
      <div className="txt-center mvxxl">
    <h1 className="phm">Unrecognizable Earth</h1>
    <div className="mtm">
        <p className="mbn title-font">By Mike McDearmon</p>
        <p className="mtn txt-low-emph txt-center">Dec 16, 2020 | 5 min read</p>
    </div>
      </div>
      <p>{`There are few places left on Earth where we can experience natural systems that haven't been altered by human activity. From the tropics to the poles, we have transformed landscapes, supplanted species, and altered the chemistry of climate systems. Ours is a world reshaped by human intervention, an expression of the values of industrialized society.`}</p>
      <p>{`Around 8000 years ago humans were learning to fashion tools out of metal for the first time. How they developed this ability was probably not a stroke of genius, but an observation of a natural process at work. Like we do today, people assembled stone pits for campfires thousands of years ago. When a stone rich in metal was placed in a fire ring and heated up past its melting point, liquid metal oozed out of the rock and took on a new shape as it cooled. If you’ve never observed metal ooze out of a campfire stone, you’re in good company. Most metal-rich surface rocks near inhabited areas were extracted long ago, so this natural phenomenon which sparked one of the most significant technological innovations in human history is in many ways a thing of the past `}<span className="sup">{`[1]`}</span>{`.`}</p>
      <p>{`Modern forests are also the product of past intervention. The logging industry has worked its way through the majority of American forests at least once, extracting large ancient trees for lumber and clearing out a multitude of species to make room for the more profitable ones. What remains are younger and less diverse forests than what stood before, though it's often difficult for a casual observer to notice `}<span className="sup">{`[2]`}</span>{`. Visible evidence of intervention can fade fast as new growth fills in the gaps left by logging. These altered forests often appear healthy, dense with life, but devoid of variety.`}</p>
      <p>{`Tampered with or otherwise, there are fewer forests today than in the recent past. A 2015 study from the Yale School of Forestry & Environmental Studies estimates that the total number of trees on Earth, about 3 trillion, has dropped by roughly 46 percent since the start of human civilization `}<span className="sup">{`[3]`}</span>{`. In the year 1089, the Domesday Survey of England revealed that humans had already deforested over 85 percent of the English countryside. And in Iceland, less than 2 percent of original forest cover remains, much of it cleared away by early settlers in the 9th century for fuel, shelter, and open space to raise sheep `}<span className="sup">{`[4]`}</span>{`. Ironically, Iceland is now famous for its dramatic treeless vistas—otherworldly in beauty though artificial in origin. `}</p>
      <p>{`Industrialization has transformed landscapes at the continental scale. Chestnut blight, a fungus imported to the US in 1904 thanks to increased international travel, reshaped forests of the American East by killing an estimated 3 to 4 billion American Chestnut trees `}<span className="sup">{`[5]`}</span>{`. These trees were not only any important food source, they were massive. Their trunks averaged 5 to 7 feet in diameter, earning them the nickname ‘Redwoods of the East’. While evidence of their mass die-off is no longer visible to anyone wandering an eastern American forest today, the surviving ecosystem is less diverse, resilient, and magnificent as a result.`}</p>
      <div className="mtxxl txt-center">
        <div className="story-image--chestnut"></div>
        <p className="phxl mbxxl caption">This photo taken in the early 1900s in what is now Great Smoky Mountains National Park shows the size some American chestnut trees reached before the blight killed an estimated 3-4 billion of them. (Great Smoky Mountains National Park/Via Associated Press).</p>
      </div>
      <p>{`Industrial innovation has also accelerated the reduction and redistribution of animal populations. The central plains of North America were once home to an estimated 25 to 30 million bison, roughly equivalent to the human population of Texas today. Roaming the plains much like the wildebeest of Africa, North American bison became the target of a punctuated slaughter that brought the number of living animals down to less than 100 by the late 1800s `}<span className="sup">{`[6]`}</span>{`. `}</p>
      <p>{`A confluence of factors drove the killings. New technology made tanning bison hide easier and international demand skyrocketed. At the same time, bison were caught in the middle of America’s war with the Indigenous who depended on them for food and processed their remains into clothing, tools, and building materials. The US army made a mission out of slaughtering bison as a means of starving the Indigenous into submission `}<span className="sup">{`[7]`}</span>{`. `}</p>
      <div className="mtxxl txt-center">
        <div className="story-image--bison"></div>
        <p className="phxl mbxxl caption">This photo from 1892 shows two men posing with an enormous pile of bison skulls. These skulls were likely ground down into fertilizer. (Rougeville, Michigan/Via Burton Historical Collection at the Detroit Public Library).</p>
      </div>
      <p>{`The majority of remaining bison now roam in Yellowstone National Park. While bison numbers have rebounded somewhat, only one percent of their pre-industrial population survives on a fraction of their former territory. That territory is now home to a near endless patchwork of cattle ranches and croplands—a meticulously cultivated and partitioned landscape incompatible with the wild roaming lifestyle of its former inhabitants.`}</p>
      <div className="mtxxl txt-center">
        <div className="story-image--fields"></div>
        <p className="phxl mbxxl caption">This satellite view above the border between Colorado and Nebraska is typical across the modern Great Plains (Holyoke, Colorado/Via Google).</p>
      </div>
      <p>{`Habitats can rebound after calamity, but they are not fully healed. Like scar tissue on a muscle, an environment with fewer species is functional yet fragile, vulnerable to stresses that diverse ecosystems can absorb more effectively. And on the human timescale, the loss of biodiversity is forever. After a mass extinction, the amount of time needed for ecosystems to recover is 5 to 10 million years `}<span className="sup">{`[8]`}</span>{`.`}</p>
      <p>{`Natural abundance still exists, but we are nonetheless living on a planet transformed and depleted—and that transformation is accelerating through industrial land use and destabilized climate systems. From coastal cities to alpine glaciers, the world we inhabit decades from now may feel more alien than familiar. But no future is certain and reducing the rate of global change remains possible. Mary Anne Hitt, National Director of Campaigns at the Sierra Club who has spent over a decade helping shift the United States toward a renewable energy grid, believes we have the momentum needed to alter course: "We are the architects of our future—not the fossil fuel industry... Transformation that can avert the climate crisis is possible in the decade ahead. Let's go make it happen" `}<span className="sup">{`[9]`}</span>{`. `}</p>
      <div className="references mvxxl">
    <strong>References</strong>
    <ol>
        <li>William F. Ruddiman, <a href="https://press.princeton.edu/books/paperback/9780691173214/plows-plagues-and-petroleum" target="blank">Plows, Plagues, and Petroleum: How Humans Took Control of Climate</a>, Princeton University Press, 2005</li>
        <li>Brant Ran, <a href="https://www.ran.org/the-understory/how_much_old_growth_forest_remains_in_the_us/" target="blank">How Much Old Growth Forest Remains in the US?</a>, Rainforest Action Network</li>
        <li>Crowther, T., Glick, H., Covey, K. et al., <a href="https://www.nature.com/articles/nature14967" target="blank">Mapping tree density at a global scale</a>, Nature, 2015</li>
        <li>Matthew Magann, <a href="https://www.dartmouth.edu/~iispacs/Education/EARS18/Iceland_2017/Deforestation/Deforestation.html#:~:text=When%20the%20first%20settlers%20reached,by%20the%20early%2020th%20century." target="blank">Deforestation in Iceland</a>, Dartmouth College Earth Sciences</li>
        <li>Sandra L. Anagnostakis, <a href="https://www.apsnet.org/edcenter/apsnetfeatures/Pages/ChestnutBlightDisease.aspx" target="blank">Revitalization of the Majestic Chestnut: Chestnut Blight Disease </a>, APSnet Features, 2000</li>
        <li>M. Scott Taylor, <a href="https://www.nber.org/system/files/working_papers/w12969/w12969.pdf" target="blank">Buffalo Hunt: Internatinoal Trade and the Virtual Extinction of the North American Bison </a>, National Bureau of Economic Research, 2007</li>
        <li>J. Weston Phippen, <a href="https://www.theatlantic.com/national/archive/2016/05/the-buffalo-killers/482349/" target="blank">'Kill Every Buffalo You Can! Every Buffalo Dead Is an Indian Gone' </a>, The Atlantic, 2016</li>
        <li>ZHong-Qian Chen, Michael J Benton, <a href="https://www.researchgate.net/publication/258686498_The_timing_and_pattern_of_biotic_recovery_following_the_end-Permian_mass_extinction" target="blank">The timing and pattern of biotic recovery following the end-Permian mass extinction </a>, Nature Geoscience, 2012</li>
        <li>Mary Anne Hitt, <a href="https://www.researchgate.net/publication/258686498_The_timing_and_pattern_of_biotic_recovery_following_the_end-Permian_mass_extinction" target="blank">Beyond Coal </a>, edited by  Ayana Elizabeth Johnson and Katharine K. Wilkinson, One World, 2020, pp. 61-73</li>
    </ol>
      </div>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      